import { env } from "@/getEnv";

export async function authHeaders() {
  try {
    const auth = window.App.$auth0;
    if (!auth.isAuthenticated?.value) {
      return {};
    }

    const token = await auth.getAccessTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        UserId: auth.user.value.name,
        UserNickname: auth.user.value.nickname,
        UserEmail: auth.user.value.email,
      },
    };
  } catch (e) {
    console.error('Error getting auth headers:', e);
    return {};
  }
}

export async function queryApi(query, options = {}) {
  try {
    const headers = await authHeaders();
    const res = await fetch(`${env.apiBase}/${query}`, {
      ...options,
      headers: {
        ...headers.headers,
        ...options.headers,
      },
    });
  // Check the content type to decide how to parse the response
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return res.json();
    } else {
      return res.text();
    }
  } catch (error) {
    console.error('API query error:', error);
    throw error;
  }
}

export async function getPerms() {
  try {
    const headers = await authHeaders();
    if (headers.headers?.Authorization) {
      return queryApi("perms");
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error getting permissions:', error);
    return {};
  }
}

let sessionId = String(Math.random()).slice(2);
export async function logEvent(event, info, data, options = {}) {
  try {
    console.log("log event", event, info ?? "", data ?? "", options ?? "");
    return queryApi("events/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        site: env.site,
        event,
        info,
        data,
        sessionId,
        options,
      }),
    });
  } catch (e) {
    console.error('Error logging event:', e);
  }
}


// New method to check if user is admin
export async function isAdmin() {
  try {
    const headers = await authHeaders();
    if (headers.headers?.Authorization) {
      const response = await queryApi('check-admin-access');
    return response.isAdmin;
    }
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}
