<template lang="pug">
//- button(@click="login") Log in
.login-button

  //- div(style="display:inline-block; float:left")
  //- w-button.pa5.mr5(@click="test" bg-color="white" color="primary" ) Test
  w-button.pa5(bg-color="secondary" color="white" @click="login" v-if="!isAuthenticated") Log in
  div(v-else)
    //- span  Logged in
    w-button.pa5(color="secondary" bg-color="white" @click="logout"  style="vertical-align:top") Log out
    w-tooltip(left)
      template(#activator="{ on }" )
        w-image(v-if = "user?.picture" v-on="on" :width="40" :height="40" :src="user?.picture")
      | {{ user?.name }}
  w-dialog(v-model="showError" :overlay-opacity="0.5" width="400px" title="Error" :show-close="true")
    div {{ loginErrorDescription }}
    div(style="text-align:center")
      w-button.pa4.primary--bg.mt8(@click="finishError" color="white") OK
</template>
<script>
import { queryApi, getPerms, isAdmin } from "@/api";
export default {
  data: () => ({
    showError: false,
    loginError: null,
    loginErrorDescription: null,
  }),
  mounted() {
    // this situation occurs if using loginWithRedirect
    this.loginError = new URL(window.location.href).searchParams.get("error");
    this.loginErrorDescription = new URL(window.location.href).searchParams.get(
      "error_description"
    );
    if (this.loginError)
      console.log(this.loginError, this.loginErrorDescription);
    this.showError = !!this.loginError;
  },
  //aA@1.com
  methods: {
    async login() {
      // this.$auth0.loginWithRedirect();
      try {
        await this.$auth0.loginWithPopup();
      } catch (e) {
        this.loginErrorDescription = e.message;
        this.showError = true;
        console.error(e);

        // if we don't log them out, they can't try logging in with a different account
        // this.$auth0.logout({
        //   logoutParams: { localOnly: true, returnTo: window.location.origin },
        // });
      }
    },
    finishError() {
      this.showError = false;
      this.logout();
    },
    logout() {
      this.$auth0.logout({
        logoutParams: { localOnly: true, returnTo: window.location.origin },
      });
    },
  },
  computed: {
    isAuthenticated() {
      return this.$auth0?.isAuthenticated?.value;
    },
    user() {
      return this.$auth0?.user?.value;
    },
  },
  watch: {
    async isAuthenticated() {
      this.$emit("update:isAuthenticated", this.isAuthenticated);
      if (this.isAuthenticated) {
        const perms = await getPerms();
        window.eventBus.emit("update:perms", null, perms);
        const admin = await isAdmin();
        window.eventBus.emit("update:admin", null, admin);
      }
    },
  },
};
</script>

<style>
.login-button .w-image {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.login-button {
  display: flex;
}
</style>
