<script setup></script>

<template lang="pug">

#Legend.mt12
  .captions(style="width: 100%; display:flex; justify-content: space-between")
    span {{ lowLabel }}
    span {{ highLabel }}
  #horizontal-ramp(v-if="colorRamp" style="width: 100%; display:flex")
    .box(v-for="[color, min, max] in colorRamp" :style="{background: color, width: (100/colorRamp.length) + '%', height: '20px'}")

</template>

<script>
import metadata from "@/metadata.json";
import { colorsForIndicator } from "@/colorRamps";
export default {
  props: ["indicator"],
  created() {
    window.Legend = this;
  },
  computed: {
    colorRamp() {
      const colors = colorsForIndicator(this.indicator);
      return colors.map((c, i) => [c, i * 10, i * 10 + 9]);
    },
    lowLabel() {
      return this.indicatorDef.source === "census" ? "Low" : "Worst";
    },
    highLabel() {
      return this.indicatorDef.source === "census" ? "High" : "Best";
    },
    indicatorDef() {
      return metadata.indicators[this.indicator];
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
}
.swatch {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
