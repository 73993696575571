import { env } from "@/getEnv";
import { safeAddTiles } from "./safeAddTiles";
import mapboxgl from "mapbox-gl";
export async function addSchoolOverlays({ map }) {
  for (const year of ["2018", "2021", "2024"]) {
    const sourceId = `schools-${year}`;

    safeAddTiles({
      map,
      url: `${env.tilesBase}/schools_${year}/index.json`,
      sourceId,
    }).then(() => {
      map.U.addCircle(`schools-${year}-circle`, `schools-${year}`, {
        sourceLayer: `schools`,
        circleColor: "hsla(240,80%,70%,0.5)",
        circleStrokeColor: "hsla(240,80%,30%,0.9)",
        circleStrokeWidth: 2,
        circleRadius: 2,
      });
      !env.offline &&
        map.U.addSymbol(`schools-${year}-text`, `schools-${year}`, {
          sourceLayer: "schools",
          textField: ["get", "school_name"],
          textSize: 12,
          minzoom: 13,
          textAnchor: "left",
          textJustify: "left",
          textOffset: [0.5, 0],
        });
      // ideally hover-over at low zoom
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: "school-popup",
      });
      map.on("mousemove", `schools-${year}-circle`, (e) => {
        popup
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(e.features[0].properties.school_name)
          .addTo(map);
      });
      map.on("mouseleave", `schools-${year}-circle`, () => {
        popup.remove();
      });
      map.U.setFilter(new RegExp(sourceId), false);

      if (sourceId === "schools-2018") {
        map.U.hideSource("schools-2018");
      } else {
        // map.U.hoverPopup("schools-2018", (l) => l.properties.school_name);
      }
    });
  }
}

export function updateSchoolOverlays({ map, filter }) {
  const filterExpr = {
    Combined: ["==", ["get", "type_"], "Combined"],
    Primary: ["==", ["get", "type_"], "Primary"],
    Secondary: ["==", ["get", "type_"], "Secondary"],
    Special: ["==", ["get", "type_"], "Special"],
    All: true,
    None: false,
    "": false,
  }[filter];
  /*
        },
        {
          label: "Primary",
          value: ["==", ["get", "type_"], "Primary"],
        },
        {
          label: "Secondary",
          value: ["==", ["get", "type_"], "Secondary"],
        },
        {
          label: "Special",
          value: ["==", ["get", "type_"], "Special"],
        },
        {
          label: "All",
          value: true,
        },
        {
          label: "None",
          value: false,
        },
        */
  map.U.setFilter(/schools-/, filterExpr);
}
