<script setup>
// import { RouterLink, RouterView } from "vue-router";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import Map from "@/components/Map.vue";
import EventBus from "js-event-bus";
import BoundarySelector from "@/components/BoundarySelector.vue";
import IndicatorSelector from "@/components/IndicatorSelector.vue";
import IndicatorCard from "@/components/IndicatorCard.vue";
import Legend from "@/components/Legend.vue";
import LoginButton from "@/components/LoginButton.vue";
import YearSelector from "@/components/YearSelector.vue";
import OverlaySelector from "@/components/OverlaySelector.vue";
import IsochroneTool from "@/components/IsochroneTool.vue";
import BasemapSelector from "@/components/BasemapSelector.vue";
import CsvDownloader from "@/components/CsvDownloader.vue";
import ComparisonModeSelector from "@/components/ComparisonModeSelector.vue";
import config from "@/config";
import { env } from "@/getEnv";
import CsvUpload from "@/components/CsvUpload.vue";
window.eventBus = new EventBus();

const site = computed(() => (env.site === "prod" ? "" : env.site));

const blockEditing = async () =>
  nextTick().then(() =>
    document
      .querySelectorAll("[contenteditable=true]")
      .forEach((el) => (el.contentEditable = false))
  );
onMounted(blockEditing);

const boundaryType = ref(config.firstBoundaryType);
const visForm = {
  indicator: config.firstIndicator,
  year: config.firstYear,
};

const vis = ref({ ...visForm });
const vis2 = ref({ ...visForm });

const comparisonMode = ref("single");
watch(comparisonMode, () => {
  window.setTimeout(() => window.map.resize(), 10);
  if (comparisonMode.value === "single") {
    selectedBoundary2.value = null;
  }
});
const twoMaps = computed(() => comparisonMode.value !== "single");
const indicators = computed(() => {
  const ret = [vis.value.indicator];
  if (twoMaps.value) {
    ret.push(vis2.value.indicator);
  }
  return ret;
});

const selectedBoundary = ref(null);
const selectedBoundary2 = ref(null);
watch(selectedBoundary, () => {
  if (selectedBoundary.value) {
    showDrawer.value = true;
  }
});
const overlays = ref({});
const settingIsochrone = ref(false);

function clickCompare() {
  comparisonMode.value =
    comparisonMode.value === "single" ? "compareIndicators" : "single";
  if (comparisonMode.value === "compareIndicators") {
    vis2.value.indicator = vis.value.indicator;
    vis2.value.year = vis.value.year;
  }
}

watch(comparisonMode, () =>
  window.eventBus.emit("set-comparison-mode", null, comparisonMode.value)
);

function updateBoundaryYear(year) {
  [vis.value.year, vis2.value.year] = year.split(",");
  if (vis2.value.year) {
    comparisonMode.value = "compareYears";
  } else if (comparisonMode.value === "compareYears") {
    comparisonMode.value = "single";
  }
}

const showSettings = ref(false);
const showDrawer = ref(false);
watch(showSettings, blockEditing);
</script>

<template lang="pug">
form(spellcheck="false")
  // note: .smu- means "on SMall sizes and Up, ...". .smd- is "on SMall sizes and Down", .mdu- is "medium and up", etc.

  div(style="height: 100vh; max-height:100vh; overflow:hidden !important;")


    w-flex.column.fill-height
      header.xs12.primary.white--bg.px4(style="display:flex; justify-content: space-between;")
        w-flex.grow
          w-image(src="RMIT AUO_Brandmark_Horizontal_pos_RGB.png" width="180px" height="72px")
          span.secondary.ml4.px2(v-if="site" style="text-transform:uppercase; font-weight:900; border:0px solid; border-radius:8px")  {{ site }}
          //- .xs-hide.title2.ml12 Australian Urban Observatory
          //-   span.secondary.ml4.px2(v-if="site" style="text-transform:uppercase; font-weight:900; border:1px solid; border-radius:8px")  {{ site }}
          //- .smu-hide.title5.ml12 Australian Urban Observatory
          //-   span.secondary.ml4.px2(v-if="site" style="text-transform:uppercase; font-weight:900; border:1px solid; border-radius:8px")  {{ site }}
        // Place CsvUpload before LoginButton in header
        CsvUpload
        LoginButton.my2
      main.grow.white--bg
        w-flex.row.info-light3--bg.fill-height
          .map-container.grow.gray--bg(style="position:relative")

            w-dialog(v-model="showSettings")
              form(spellcheck="false")
                div(style="display:flex; justify-content: space-between;")
                  BoundarySelector.grow.mr8(@update:boundaryType="boundaryType = $event")
                  YearSelector(@update:boundaryYear="updateBoundaryYear($event)")
                OverlaySelector(@changeOverlays="overlays = $event" )
            w-dialog(:model-value="needLogin" width="600px" persistent)
              h2 Welcome to the Australian Urban Observatory map.
              //- p.mt4 This digital liveability planning platform transforms complex urban data into easily understood liveability maps across Australia’s 21 largest cities.{{' '}}

              .mt4
              p Please login and create an account to see the AUO liveability map.
              .mt4
              p If your organisation has an existing {{' '}}
                a(href="https://auo.org.au/partner/") Research Partnership{{' '}}
                | with the AUO, then please use your organisational email address when you create a new account.
              .mt4
              .mxa.mt6.w-flex.justify-center
                LoginButton.mr8
                w-button(text route="https://auo.org.au/about/") Learn more...

              p.mt6.size--sm Use is subject to our {{' '}}
                a(href="https://auo.org.au/terms-of-use/" target="_blank") terms and conditions {{' '}}
                span and {{' '}}
                a(href="https://www.rmit.edu.au/utilities/privacy" target="_blank") RMIT privacy policy
                span .

            //- Main (left-hand side) map and selector
            Map(container="map" :boundaryTypeYear="`${boundaryType}_${vis.year}`" :indicator="vis.indicator" @select-boundary="selectedBoundary=$event" :overlays="overlays" :settingIsochrone="settingIsochrone")

            .pb4(style="max-width: 600px; top: 20px; left: 20px; right: 20px; position: absolute; ")
              .sh4(style="background: white")
                IndicatorSelector.pb4.grow(:boundaryType="boundaryType" @update:indicator="vis.indicator = $event; vis2.indicator = $event" :suffix="vis.year")
                  .ml4
                  CsvDownloader(:boundaryType="boundaryType" :year="vis.year")

                  w-tooltip
                    template(#activator="{ on }")
                      w-button(v-on="on" xl
                        @click.prevent="clickCompare()"
                        icon="mdi mdi-compare"
                        color="primary"
                        :style="{ background: comparisonMode === 'compareIndicators' ? 'hsl(169.5, 69.6%, 92%)': 'white', border: comparisonMode === 'compareIndicators' ? '1px solid var(--w-primary-color)' : '' }") mdi mdi-compare
                    | Compare indicators

              w-button.mdu-hide.mt4.py6(xl stayle="position:absolute; bottom: 80px; left: 20px" @click="showSettings = true")
                w-icon mdi mdi-menu

            div(style="position:absolute; right:10px; bottom: 40px;")
              BasemapSelector(mapId="map")
          // Second (RHS)
          .map-container.grow.gray--bg(v-if="twoMaps" style="position:relative")
            Map( container="map2" :boundaryTypeYear="`${boundaryType}_${vis2.year}`" :indicator="vis2.indicator" style="border-left:2px solid grey" @select-boundary="selectedBoundary2 = $event" :overlays="overlays" :settingIsochrone="settingIsochrone")

            .pb4.sh4(style="max-width: 600px; top: 20px; left: 20px; right: 30px; position: absolute; background: white")
              .w-flex.py1.justify-space-between.pa3.bg-primary(v-if="comparisonMode === 'compareYears'" style="background: var(--w-primary-color); color: white; font-size:18px")
                | {{ vis2.year }}
                w-icon.pointer.white(style="margin-top:0px" xl @click="comparisonMode='single'") mdi mdi-close
              div(v-if="comparisonMode === 'compareIndicators'")
                IndicatorSelector.pb4(:boundaryType="boundaryType" @update:indicator="vis2.indicator = $event" :suffix="vis2.year")
                  template(v-slot:top)
                    w-icon.pointer.white(style="margin-top:6px" xl @click="comparisonMode='single'") mdi mdi-close

              .w-flex.justify-space-between
                //- YearSelector.ml4.mt4(v-if="comparisonMode === 'compareYears'" @update:boundaryYear="vis2.year = $event" )
                .ma2
                  CsvDownloader.ma20(v-if="comparisonMode === 'compareYears'" :boundaryType="boundaryType" :year="vis2.year")

            div(style="position:absolute; right:10px; bottom: 40px")
              BasemapSelector(mapId="map2")
          div( style="position:absolute; bottom:80px; left:-5px; width:300px; ")
            Legend.mr4.ml4(:indicator="vis.indicator")
          //- .smd-hide( style="position:absolute; bottom:80px; left:0; right: 470px; ")
          //-     Legend.mr4.ml4(:indicator="vis.indicator")
          //- .mdu-hide( style="position:absolute; bottom:80px; left:0; right: 10px; ")
          //-   Legend.mr4.ml4(:indicator="vis.indicator")
          w-drawer.mdu-hide(v-model="showDrawer" bottom height="70%")
            div(style="overflow-y:scroll")
              IndicatorCard(:boundaryType="boundaryType" :indicators="indicators" :selectedBoundaries="[selectedBoundary, selectedBoundary2].filter(Boolean)" v-if="selectedBoundary || selectedBoundary2" :twoMaps="twoMaps")
              p(v-else) Select

          .sidebar.white--bg.pa2.smd-hide(style="width:460px; height:calc(100vh - 72px); overflow-y:scroll; overflow-x:hidden;")
            #geocoder-container(style="min-height:50px")
            form(spellcheck="false")
              div(style="display:flex; justify-content: space-between;")
                BoundarySelector.grow.mr8(@update:boundaryType="boundaryType = $event")
                YearSelector(@update:boundaryYear="updateBoundaryYear($event)")
              OverlaySelector(@changeOverlays="overlays = $event" )
              IsochroneTool(@changeSettingIsochrone="settingIsochrone = $event")
              //- Legend(:indicator="vis.indicator")
              IndicatorCard.mt12(:boundaryType="boundaryType" :indicators="indicators" :selectedBoundaries="[selectedBoundary, selectedBoundary2].filter(Boolean)" v-if="selectedBoundary || selectedBoundary2" :twoMaps="twoMaps")
              div.pt12(v-else)
                h3 Please select a boundary on the map to see liveability results.


      //- footer.w-flex.px4.white--bg(style="max-height:20px") Footer not currently needed
</template>
<script>
export default {
  created() {
    window.App = this;
  },
  computed: {
    isAuthLoading() {
      return this.$auth0?.isLoading?.value;
    },
    needLogin() {
      return (
        !this.$auth0?.isLoading?.value && !this.$auth0?.isAuthenticated?.value
      );
    },
  },
  watch: {
    twoMaps() {
      window.setTimeout(() => window.map.resize(), 100);
    },
    isAuthLoading() {
      if (!this.isAuthLoading) {
        this.$emit("update:isAuthenticated", this.isAuthenticated);
      }
    },
  },
  methods: {},
};
</script>
<style>
@import "style.css";
@import "@/assets/poppins.css";

.w-app {
  font: 14px/1.4 Poppins, sans-serif;
  /* padding: 24px; */
}

.b {
  font-weight: bold;
}

a {
  color: var(--w-primary-color);
}

a:hover {
  filter: brightness(75%);
}

.w-select__selection:hover {
  filter: brightness(85%) saturate(200%);
}

.dib {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}
</style>
