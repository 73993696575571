import metadata from "@/metadata.json";
export function colorsForIndicator(indicator) {
  const def = metadata.indicators[indicator];
  // const colors = [
  //   "#67001f",
  //   "#b2182b",
  //   "#d6604d",
  //   "#f4a582",
  //   "#fddbc7",
  //   "#d1e5f0",
  //   "#92c5de",
  //   "#4393c3",
  //   "#2166ac",
  //   "#053061",
  // ];

  const colors = [
    "#276419",
    "#4d9221",
    "#7fbc41",
    "#b8e186",
    "#e6f5d0",
    "#fde0ef",
    "#de77ae",
    "#de77ae",
    "#c51b7d",
    "#8e0152",
  ].reverse();

  const censusColors =
    "#904222 #bf572d #ee703a #fd8a43 #fd9838 #fdc151 #fddf7b #fcf296 #feffc5 #fffff3"
      .split(" ")
      .reverse();

  return def.source === "census" ? censusColors : colors;
  // return def.polarity === "DESC" ? colors.reverse() : colors;
}

export function colorForIndicatorPercentile(indicator, percentile) {
  const colors = colorsForIndicator(indicator);
  return colors[
    Math.min(
      Math.max(0, Math.floor((percentile / 100) * colors.length)),
      colors.length - 1
    )
  ];
}

export const colorByIndicator = {
  urban_liveability_index: "#88c653",
  walkability_index: "#00aeef",
  social_infrastructure_index: "#fba919",
  transport_percent_dwellings_400m_regular_pt: "#00529c",
  food_closest_healthy_food: "#05b59a",
  alcohol_number_off_licences_800m: "#e91d31",
  pos_percent_400m_large: "#90278f",
  employment_percent_working_locally: "#ee4c9b",
  housing_low_income_housing_stress: "#35459d",
};
